<template>
    <div class="my">
        <div style="padding:10px;font-size:15px;">{{$t("title20")}}</div>
          <div class="userInfo">
              <div class="userInfo1">
                  <div>
                      <img src="../assets/cryimg/head.b8e5d31e.png"/>
                      <div style="text-align: left;">
                         <div>{{userInfo.user_name}}</div>
                         <div>VIP{{userInfo.vip_level}}</div>
                      </div>
                  </div>
                  <a class="setting" @click="goSetting">
                      <img src="../assets/cryimg/setting.png"/>
                  </a>

              </div>
            
          </div>
          <div class="preview">
              <div class="preview_content">
                <div style="flex:1" >
                  <div class="preview_title">{{$t('title21')}}(USDT)</div>
                  <div class="preview_content1" style="text-align:left;margin-top:10px;font-size:15px;">
                    {{userInfo.balance}} 
                  </div>
                </div>
                 <div  style="flex:1">
                  <div class="preview_title">{{$t('title57')}}(USDT)</div>
                  <div class="preview_content1" style="text-align:left;margin-top:10px;font-size:15px;">
                    {{userInfo.lock_balance}} <button @click="showRefundTip" class=" input_btn  btnred refund">{{$t('title122')}}</button>
                  </div>
                </div>
              </div>
          </div>
          <div class="fn_crossing">
              <div class="fn_crossing_content">
                  <div @click="goRecharge">
                       <div> <img src="../assets/cryimg/deposit_icon.png"/></div>
                      <div>{{$t('title22')}}</div>
                  </div>
                    <div @click="goDraw">
                       <div> <img src="../assets/cryimg/withdraw_icon.png"/></div>
                      <div>{{$t('title23')}}</div>
                  </div>
                     <div @click="goTransfer">
                       <div> <img src="../assets/cryimg/transfer_icon.png"/></div>
                      <div>{{$t('title11')}}</div>
                  </div>
                   <div @click="goVip">
                       <div> <img src="../assets/cryimg/viplevel_icon.png"/></div>
                      <div>{{$t('title12')}}</div>
                  </div>
              </div>
          </div>
          <div class="line"></div>
          <div class="services">
              <div class="services_content">
                   <van-cell :title="$t('title26')" is-link style="align-items:center" url="#/message">
                      <template #icon>
                        <img src="../assets/cryimg/message.png" style="width:20px;height:20px;margin-right:5px" />
                    </template>
                    <template #default>
                        <div v-if="messageCount>0" style="padding: 5px;
    background: red;
    color: rgb(255, 255, 255);
    width: 12px;
    height: 12px;
    text-align: center;
    float: right;
    border-radius: 18px;
    line-height: 12px;">{{messageCount}}</div>
                    </template>
                   </van-cell>
                   <van-cell :title="$t('title27')" is-link style="align-items:center" url="#/buynow">
                      <template #icon>
                        <img src="../assets/cryimg/h5_sf.png" style="width:20px;height:20px;margin-right:5px" />
                    </template>

                   </van-cell>
                   <van-cell :title="$t('title28')" is-link url="#/help">
                        <template #icon>
                        <img src="../assets/cryimg/help.png" style="width:20px;height:20px;margin-right:5px" />
                       </template>
                   </van-cell>
                 
              </div>

              <a @click="exit" style="background: rgb(88, 139, 248);
    opacity: 0.97;
    padding: 10px;
    width: 90%;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    font-size:14px;
    color: #ffffff;">{{$t("title29")}}</a>
          </div>
    </div>
</template>
<script>
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
 import store from '@/store'
export default {
    data(){
        return{
          personPng:require("../assets/img/ic_my_service_1.png"),
          notePng:require("../assets/img/ic_my_service_2.png"),
          dayPng:require("../assets/img/ic_my_service_3.png"),
          accountPng:require("../assets/img/ic_my_service_4.png"),
          teamPng:require("../assets/img/ic_my_service_6.png"),
          invitePng:require("../assets/img/ic_my_service_5.png"),
          helpPng:require("../assets/img/ic_my_service_7.png"),
          profilePng:require("../assets/img/ic_my_service_8.png"),
          downloadPng:require("../assets/img/ic_my_service_9.png"),
          userInfo:{},
          messageCount:0
        }
        
    },
    methods:{
        showRefundTip(){
             

            this.$dialog.alert({
                title:this.$t('title122'),
      message: this.$t('title65')+"\n"+this.$t('title66'),
    });
        },
        goVip:function(){
            this.$router.push({
                path: "/vip"
            });
        },
        exit:function(){
            window.localStorage["token"]="";
              store.dispatch('setUserInfo',null);
                        this.init();
        },
        goSetting:function(){
                    this.$router.push({
                path: "/settings"
            });
        },
        download:function(){
 window.open("https://www.youtubeow.com/yow.apk?random="+new Date().getTime(),"_blank");
        },
        goWallet:function(){
            this.$router.push({
                path: "/wallet"
            });
        },
        goInvestment:function(){
            this.$router.push({
                path: "/investment"
            });
        },
        goDraw:function(){
            this.$router.push({
                path: "/withdraw"
            });
        },
        goRecharge:function(){
            this.$router.push({
                path: "/recharge"
            });
        },
        goPerson:function(event){
             this.$router.push({
              path: "/person"
             });
        },
        goDailyStatement:function(){
             this.$router.push({
              path: "/dailyStatement"
             });
        },
        goTeam(){
            
             this.$router.push({
              path: "/team"
             });
        },
        goTransfer(){
this.$router.push({
              path: "/tranfer"
             });
        },
        goInvite(){
            
             this.$router.push({
              path: "/invite"
             });
        },
        init:async function(){
          this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            
             
             
            var userInfo=await getUserInfo();
            if(userInfo==null||!userInfo){
                this.$router.push({
                    path: "/login",
                    query:{src:"my"} 
                });
                this.$toast.clear();
                return;
            }
            this.userInfo=userInfo;
            console.log(this.userInfo);
           this.$toast.clear(); 
        },
        getLastMessage:function(){
              post("api/user/getUnreadMessage",{}).then(res=>{
                this.messageCount=res.datas;
              })
        },
        goOut:function(){
            window.localStorage["token"]="";
             this.init();
        }
    },
    created(){
       
          if(this.$route.query.index){
             this.$store.dispatch("setMenuActive",parseInt(this.$route.query.index));
           }
    },
    mounted(){
        setInterval(async ()=>{
            console.log("定时更新用户信息");
            var userInfo=await getUserInfo(1);;///隔几秒更新用户金额信息
            this.userInfo=userInfo;
        },5000)
        
    },
    activated(){
        console.log("activated");
        this.$store.dispatch("setShowNav",true);
        this.getLastMessage();
         this.init();
    }
}
</script>
<style>

.input_btn{
        text-transform: uppercase;
    font-weight: 500;
    margin-top: 12px;
    width: 100%;
    height: 48px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background: #588bf8;
    cursor: pointer;
    -webkit-transition: all .25s;
    transition: all .25s;
    border-radius: 30px;
}

button {
    background-color: transparent;
    resize: none;
    border: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.btnred {
    background: #588bf8;
    opacity: 0.97;
}
.refund{
        display: inline-block;
    width: auto;
    height: auto;
    font-size: 12px;
    padding: 5px 8px;
    margin-left: 6px;
}
.preview_content{
    background: url("../assets/cryimg/user-bg.0e328f9.png");
    color:#ffffff;
    background-size: 100% 100%;
    display:flex;
    padding:15px;
}
.my .van-grid-item__text{
    word-break:break-word;
}
.my .van-icon__image{
    width:24px;
    height:24px;
}
</style>
<style scoped>

 .services{
padding-bottom:50px;
 }
 .services_title{
     margin-bottom: 10px;
 }
.services_content{
    margin:12px 12px;
    padding:16px 0px; 
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    font-size:14px;
    flex-direction: column;
    text-align:left;
}
.fn_crossing_content{
    margin:12px 12px;
    padding:16px 0px; 
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    font-size:14px;
}
.fn_crossing_content>div{
    display:flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.fn_crossing_content>div img{
   width:32px;
   height:32px;
}
.griditem{
    display: flex;
    flex-direction: column;
    width:100%;


}
.griditem .price{
    font-size:16px;
    color:#333333;
    font-weight: bold;
    margin-top:6px;
}
.griditem>div{
    display: flex;
    justify-content: flex-start;
    text-align: left;
    font-size:12px;
}
.preview_title,.services_title{
    font-size: 15px;
    display: flex;
    justify-content: flex-start;
}
.preview_content1{
}
.preview_content{
    margin:0px 12px; 
    border-radius: 10px;


}
.balance{
    color:#F9034F;
   font-size:16px !important;
    font-weight:bold;
}
.otherBalance{
   color:#000000;
    font-size:16px !important;
    font-weight:bold; 
}
 .my_money{
     display:flex;
     flex-direction: row;
     padding:0px 15px;
     margin-top:-10px;
 }
 .my_money>div{
     
     flex-shrink: 0;
     flex:1;
     height:66px;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
 }
  .my_money>div>div{
      flex-wrap: wrap;
      font-size: 12px;
  }
 .my{
    background: #ffffff; 
    text-align:center;
    
 }
.line {
    height: 3px;
    background: #e3e3e3;
}
 .setting{
     position: absolute;
     top:40px;
     right:16px;
 }
 .setting img{
     width:21px;
     height:21px;
 }
 .userInfo1{
     padding:0px 15px;
     height:100px;
     background-size: contain;
     display: flex;
     align-items: flex-end;
     position: relative;
     
 }
 .userInfo1>div{
     display:flex;
     align-items: center;
     font-weight: bold;
     font-size:15px;
     margin-bottom:24px;
 }
 .userInfo1>div img{
     width:45px;
     height:45px;
     border-radius: 40px;
     margin-right:10px;
 }
</style>
