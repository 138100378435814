<template>
    <div class="business_hall">
         <div class="business_hall_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">{{$t("title97")}}</div>
                </template>
                
            </van-nav-bar>
        </div>
        <div class="buywrap">
               <van-cell :title="$t('title98')" is-link url="https://paxful.com"/>
               <van-cell :title="$t('title99')" is-link url="https://changenow.io/" />
               <van-cell :title="$t('title100')" is-link url="https://changelly.com"/>
               <van-cell :title="$t('title101')" is-link url="https://www.binance.com/en"/>
               <van-cell :title="$t('title102')" is-link url="https://www.bybit.com.hk/zh-HK/"/>
               <van-cell :title="$t('title103')" is-link url="https://okx.com"/>

        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    methods:{
        onClickLeft:function(){
              this.$router.back(-1)
        }
    },
    activated(){
        this.$store.dispatch("setShowNav",false);
    }
}
</script>    