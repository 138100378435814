<template>
    <div class="business_hall">
         <div class="business_hall_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">{{$t('title63')}}</div>
                </template>
                
            </van-nav-bar>
        </div>
        <div class="buywrap">
               <div style="padding:15px 10px;    display: flex;
    font-size: 16px;
    align-items: center;border-bottom: 1px solid #ccc;" v-for="(item,index) in records" :key="index">
                  <div style="flex-grow:1">
                    <div>{{$t('title19')}}</div>
                    <div style="font-size:15px">{{item.create_time}}</div>
                    </div>
                  <div style="flex-grow:1;font-size:15px">{{item.status}}</div>
                  <div style="flex-grow:1;font-size:15px">{{item.amount}}</div>
               </div>
                                <div v-if="records.length==0" style="text-align: center; margin-top: 100px;">
                <img src="../assets/cryimg/nodata.png" style="width:90px"/>
               </div>

        </div>
    </div>
</template>
<script>
 import {fetch,post} from '@/util/api'
  import {getBackData,getUserInfo} from '@/util/api'
  function formatTimestamp(timestamp) {
  // 如果时间戳是以秒为单位，乘以 1000 转换为毫秒
  const date = new Date(timestamp * 1000);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，需要加 1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
}
export default {
    data(){
        return {
           userInfo:{},
           records:[]
        }
    },
    methods:{
        formatTimestamp:function(time){
                return  formatTimestamp(time);
        },
        onClickLeft:function(){
              this.$router.back(-1)
        },
        getUserCharges:function(){
             this.$toast.loading({
                        duration:0,
                        message: 'loading...',
                        forbidClick: true,
                        loadingType: 'spinner' 
                        });
            post("/api/wallet/getUserRechargeOrder",{}).then(res=>{
                this.records=res.data;
                 this.$toast.clear();
            })
        },

        init:async function(){
            this.$toast.loading({
                        duration:0,
                        message: 'loading...',
                        forbidClick: true,
                        loadingType: 'spinner' 
                        });
             
            console.log("userGuide",this.userGuide);
            this.userInfo=await getUserInfo();
             if(this.userInfo==null||!this.userInfo){
                this.$router.push({
                    path: "/login",
                    query:{src:"vip"} 
                });
                this.$toast.clear();
                return;
            }else{
                this.$toast.clear();
            }
           
            console.log(this.userInfo);
        }
    },
    activated(){
        this.$store.dispatch("setShowNav",false);
         this.getUserCharges();
    },
    mounted(){
        this.init();
    }
}
</script>    