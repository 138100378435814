<template>
    <div class="recharge">
          <div class="recharge_header">
              <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">{{$t('title104')}}</div>
                </template>
                 
            </van-nav-bar>

          </div>
          <div class="content" style="padding-bottom:100px">
                <van-collapse v-model="activeName" accordion>
                <van-collapse-item :title="$t('title105')" name="1">
                    <pre style="white-space: pre-wrap;font-family: inherit;">{{$t('content1')}}</pre></van-collapse-item>
                <van-collapse-item :title="$t('title106')" name="2"><pre style="white-space: pre-wrap;font-family: inherit;">{{$t('content2')}}</pre></van-collapse-item>
                <van-collapse-item :title="$t('title107')" name="3">
                 <pre style="white-space: pre-wrap;font-family: inherit;">{{$t('content3')}}</pre>

                </van-collapse-item>
                 <van-collapse-item :title="$t('title108')" name="4">
                    <pre style="white-space: pre-wrap;font-family: inherit;">{{$t('content4')}}</pre>
                 </van-collapse-item>
                <van-collapse-item :title="$t('title109')" name="5">
                    <pre style="white-space: pre-wrap;font-family: inherit;">{{$t('content5')}}</pre>

                </van-collapse-item>
                 <van-collapse-item :title="$t('title110')" name="6">
<pre style="white-space: pre-wrap;font-family: inherit;">{{$t('content6')}}</pre>


                 </van-collapse-item>
                 <van-collapse-item :title="$t('title111')" name="7">
                     <pre style="white-space: pre-wrap;font-family: inherit;">{{$t('content7')}}</pre>

                 </van-collapse-item>
                 <van-collapse-item :title="$t('title112')" name="8">
<pre style="white-space: pre-wrap;font-family: inherit;">{{$t('content8')}}</pre>

                 </van-collapse-item>
                 <van-collapse-item :title="$t('title113')" name="9">
<pre style="white-space: pre-wrap;font-family: inherit;">{{$t('content9')}}</pre>

                 </van-collapse-item>
                 <van-collapse-item :title="$t('title114')" name="10">
<pre style="white-space: pre-wrap;font-family: inherit;">{{$t('content10')}}</pre>
                 </van-collapse-item>
                  
                </van-collapse>

          </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
           activeName:'0'
        }
    },
    methods:{
        onClickLeft:function(){
                this.$router.back(-1)
        }
    },
    activated(){
         
      this.$store.dispatch("setShowNav",false); 
    },

}
</script>