<template>
    <div class="recharge">
          <div class="recharge_header">
              <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">Recharge</div>
                </template>
                
            </van-nav-bar>

          </div>
          <div class="recharge_content" style="padding:20px;font-size:0.5rem;">
             <div style="display: flex;
    justify-content: space-between;border-bottom: 0.01rem solid #efefef;padding-bottom:10px;">
                <div style="align-items: center;
    display: flex;">{{$t('title37')}}:</div>
                <div style="display: flex;
    align-items: center;">
                    <img src="../assets/cryimg/trc20.png" style="width:40px;" />
                    TRC20-USDT
                </div>
             </div>
                          <div style="display: flex;
    justify-content: space-between;border-bottom: 0.01rem solid #efefef;padding-bottom:20px;padding-top:30px;">
                <div style="align-items: center;
    display: flex;">{{$t('title38')}}:</div>
                <div style="display: flex;
    align-items: center;">
                     {{order.amount}} USDT
                </div>
             </div>
            <div style="display: flex;
    justify-content: space-between;border-bottom: 0.01rem solid #efefef;padding-bottom:20px;padding-top:30px;">
                <div style="align-items: center;
    display: flex;flex-grow:1">{{$t('title126')}}:</div>
                <div style="display: flex;
    align-items: center;flex-grow:1">
                     <img :src="basepath+'/api/wallet/qrcode?id='+id" alt="QR Code" style="width:150px;">
                </div>
             </div>
             <div style="font-size:13px;padding:10px;color:#00BF8B;background:#EBFAF6;margin-top:10px;display: flex;
    align-items: center;" @click="copy(order.pay_wallet)">{{order.pay_wallet}}<img src="../assets/cryimg/copy.png" style="width:20px;"/></div>
              <div style="margin-top:10px;font-size:0.4rem;">
                {{$t('title125')}}
              </div>
          </div>

        </div>    
</template>
<script>
import {fetch,post} from '@/util/api'
 import {getUserInfo} from '@/util/api'
export default {
    data(){
        return{
           id:"",
           order:{},
           basepath:process.env.VUE_APP_BASE_API,
           timer:null
        }
    },
    methods:{
        queryOrder(){
            post("api/wallet/queryOrder",{id:this.id}).then(res=>{
               this.order=res.data;
            })
        },
        startCheckOrder:function(){
             ////定时任务，检查是否充值成功

             this.timer=setInterval(()=>{
                post("api/wallet/queryOrder",{id:this.id}).then(res=>{

                    if(res.data.status=="SUCCESS"){
                        clearInterval(this.timer);
                        this.$toast(" Recharge successful");
                        setTimeout(async()=>{
                           await getUserInfo(1);
                           this.$router.push({
                                path: "/my"
                            });
                        },1000)
                    }
                })

             },1500)
        },
        onClickLeft:function(){
              this.$router.back(-1) 
        },
        copy(text){
                         var input = document.createElement("textarea");   // 直接构建input
                input.value = text;  // 设置内容
                document.body.appendChild(input);    // 添加临时实例
                input.select();   // 选择实例内容
                document.execCommand("Copy");   // 执行复制
                document.body.removeChild(input); // 删除临时实例
                this.$toast(" copy success");
        },
        

    },
    deactivated(){
        console.log("取消定时器");
        clearInterval(this.timer);
    },
    mounted(){
       this.id=this.$route.query.id;
       this.queryOrder();
       this.startCheckOrder();
    }
}
</script>