<template>
    <div class="business_hall">
         <div class="business_hall_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">Language Switch</div>
                </template>
                
            </van-nav-bar>
        </div>
        <div class="buywrap">
               <van-cell title="English" @click="setLanguage('en')"  is-link  />
               <van-cell title="繁體中文"  @click="setLanguage('tc')" is-link  />
               <van-cell title="Deutsch" @click="setLanguage('de')"  is-link  />
               <van-cell title="Français" @click="setLanguage('fr')"  is-link  />
               <van-cell title="Italiano" @click="setLanguage('it')"  is-link  />
               <van-cell title="한국어" @click="setLanguage('ko')"  is-link  />
               <van-cell title="Español" @click="setLanguage('es')"  is-link  />
                <van-cell title="اللغة  العربية" @click="setLanguage('ar')"  is-link  />
                <van-cell title="čeština" @click="setLanguage('cs')"  is-link  />
                <van-cell title="Ελληνικά"  @click="setLanguage('el')" is-link  />
                <van-cell title="فارسی"  @click="setLanguage('fa')" is-link  />
                <van-cell title="suomi"  @click="setLanguage('fi')" is-link  />
                <van-cell title="日本語" @click="setLanguage('ja')"  is-link  />
                <van-cell title="język polski" @click="setLanguage('pl')"  is-link  />
                <van-cell title="português" @click="setLanguage('pt')"  is-link  />
                <van-cell title="limba română" @click="setLanguage('ro')"  is-link  />
                <van-cell title="русский язык" @click="setLanguage('ru')"  is-link  />
                <van-cell title="svenska" @click="setLanguage('sv')" is-link  />
                <van-cell title="Türkçe" @click="setLanguage('tr')"  is-link  />
                <van-cell title="Bahasa Indonesia" @click="setLanguage('id')"  is-link  />
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    methods:{
        onClickLeft:function(){
              this.$router.back(-1)
        },
        setLanguage:function(language){
            window.localStorage["language"]=language;
             this.$i18n.locale = language;
            this.$router.push({
                  path: "/" 
            });
        }
    },
    activated(){
        this.$store.dispatch("setShowNav",false);
    }
}
</script>    