<template>
    <div class="vip">
        <div class="vip_header">
                          <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">{{$t('title12')}}</div>
                </template>
                                 <template #right>
                    <div class="submit" @click="goRecords" >
                         <img src="../assets/cryimg/record.png" style="width:20px;"/>
                        
                    </div>
                </template>
            </van-nav-bar>
        </div>
        <div class="vip_content" style="padding-bottom:150px;">
             <div style="display: flex;
    align-content: center;
    align-items: center;justify-content: center;    flex-wrap: wrap;">
                <div class="vip_item " :class="{level_selected:item.selected}" @click="selectActive(item)" v-for="(item,index) in vips" :key="index">
                   
                    <div class="item_bg ">
                        <div style="padding-top:40px;font-weight:700;font-size:15px">
                            VIP{{item.vip_level}}
                        </div>
                    </div>
                </div>

             </div>
             <div class="rules" style="margin-top:10px;">
                     <div class="rule_title" style="display: flex;margin-bottom:25px;
    justify-content: center;
    align-items: baseline;
    font-size: 14px;">
                        <img src="../assets/cryimg/l_levelrules.png" width="25"/>
                        <span style="margin-left: 15px;
    margin-right: 15px;">{{$t('title58')}}</span>
                         <img src="../assets/cryimg/r_levelrules.png" width="25"/>
                     </div>
                     <div class="rule_table">
                           <div class="table_row">
                               <div></div>
                                <div>{{$t('title59')}}</div>
                                <div>{{$t('title60')}}</div>
                                <div>{{$t('title61')}}</div>
                           </div>
                           <div class="table_row" :class="{tableSelected:item.selected}" v-for="(item,index) in vips" :key="index">
                               <div> <img :src="vipLogos[item.vip_level]" style="width:20px;margin-right:3px;"/>Vip{{item.vip_level}}</div>
                                <div>{{item.deposit}}</div>
                                <div>{{item.daily}}</div>
                                <div>{{item.monthly}}</div>
                           </div>

                     </div>
                     <div class="rule_details">
                      <div class="mtitle">
                         <div class="square_icon"></div>
                         {{$t('title58')}}		
                      </div>
                       <div class="rule_table">
                             <div class="table_row1"> 
                                <div>{{$t('title60')}}:</div>
                                <div><div>{{selectItem!=null?selectItem.daily:0}}</div><div>USDT</div></div>
                           </div>
                           <div class="table_row1"> 
                                <div>{{$t('title61')}}：</div>
                                <div><div>{{selectItem!=null?selectItem.monthly:0}}</div><div>USDT</div></div>
                           </div>
                       </div>

                     </div>
                     <div class="tip">
{{$t('title62')}}
                     </div>
                     <div class="footer">
                <div style="display:flex;justify-content: space-between;height: 100%;
    align-items: center;">
                  <div style="font-size:15px;margin-left:10px;"><span style="font-weight:bold">{{selectItem!=null?selectItem.deposit:0}}</span> USDT</div>
                  <div>
                    <a class="btnactive withdraw " @click="purchase" >{{$t('title56')}}</a>
                  </div>
                 </div>
              </div>

             </div>

             

        </div>
   <f-dialog :vshow="showTip" :title="title" :vmsg="msg" @ok="goBuy" @cancel="cancel"></f-dialog>
       <van-popup v-model="show" position="bottom" :overlay="true" :style="{ height: '30%' }" >
          <div style="padding: 20px;
    font-size: 0.4rem;
    text-align: center;">
            <div>
                <van-icon name="cross" @click="closepop" style="float:left;font-weight:bold;font-size:0.5rem;"/>
                KEY
            </div>
            <div style="margin-top:30px">
                <input class="input-text" type="password" v-model="code" placeholder="please enter KEY"/>
            </div>
            <div style="display:flex;margin-top:20px;"><a class="btnblue" @click="goBuy">Submit</a></div>
          </div>
            </van-popup>
    </div>
</template>
<style scoped>
.rules{
    
}
.tableSelected{
        background: rgb(239, 186, 127);
}
.level_selected {
    background: url("../assets/cryimg/select_bg.108b5bbe.png") 100% no-repeat;
    background-size: 100% 100%;
    border-radius: 15px;
    padding: 15px;
    margin: 10px;
}
.btnactive{
      background: #588bf8;
    opacity: 0.97;
    cursor:pointer;
}
.withdraw{
        padding: 10px;
    color: #ffffff;
    border-radius: 0.8rem;
    font-weight: bold;
    margin-right: 15px;
    font-size:15px;
    
}
.footer{
    position:fixed;
    bottom:0px;
    width:100%;
    height:60px;
    background:#ffffff;
    border-top:1px solid rgb(239, 239, 239);
}
.tip{
    font-size:15px;
    color: #ff7043 !important;
}
.mtitle{
    display:flex;
    padding:15px;
    font-size:14px;
}
.rule_details{
    margin: 20px auto;
    border-radius: 12px;
    background-color: #7fbacb;
    padding: 0 2% 5px;
    color: #fff;
}
.square_icon{
    background: linear-gradient(270deg, #f7cb6b, #fba980);
    border-radius: 5px;
    height: 18px;
    margin-right: 10px;
    width: 7px;
}
.rule_table{
    border-radius: 12px;
    background-color: #7fbacb;
    padding: 0 0% 5px;
    color: #fff;
}
.table_row div{
    font-size:14px;
    flex:1;
    padding: 15px 0px 15px 0px;
    text-align: center;
    display: flex;
    justify-content: center;
}
.table_row{
    display:flex;
   
    align-items:center;
}
.table_row1{
    display:flex;
   
    align-items:center;
}

.table_row1 div{
    font-size:15px;
    flex:1;
    padding: 5px 0px 5px 0px;
    text-align: center;
    display: flex;
    justify-content: center;
}
.vip_item{
    border: 1px solid #f7f7f7;
    border-radius: 15px;
    padding: 15px;
    margin: 10px;
}
.item_bg{
    background: url("../assets/cryimg/level_bg.png");
    background-size: 100% 100%;
    height: 80px;
    width: 60px;
    color: #fb8c00 !important;
    text-align: center;
}
.input-text, .input-select {
    width: 100%;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 6px;
    color: #0a0a0a;
    background: #f6f6f6;
    font-size: 14px;
    caret-color: #3c6fef;
    outline: none;
    -webkit-transition: all .3s;
    transition: all .3s;
    border:none;
}
::placeholder {
  color: gray;
  opacity: 0.4; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12-18 */
  color: gray;
}
.btnblue {
        background: #507ae6;
    color: #fff;
    width: 100%;
    display: block;
    padding: 9px;
    border-radius:5px;
}
.withdraw{
        padding: 12px;
    color: #ffffff;
    border-radius: 0.8rem;
    font-weight: bold;
    margin-right: 15px;
    font-size:15px;
    margin-right:30px;
        min-width: 100px;
    display: block;
    text-align: center;
}
.status-v1 {
    background: #f7a55b;
    color: #9f4a07;
    display: block;
    padding: 12px;
    overflow: hidden;
    border-radius: 4px;
}
.status-v2 {
    background: #ce623e;
    color: #ffffff;
    display: block;
    padding: 12px;
    overflow: hidden;
    border-radius: 4px;
}
.status-v3 {
      background: #cfdbd1;
    color: #8f8a6c;
    display: block;
    padding: 12px;
    overflow: hidden;
    border-radius: 4px;
}
.status-v4{
    background: #cdb8a3;
    color: #9d4a04;
    display: block;
    padding: 12px;
    overflow: hidden;
    border-radius: 4px;
}
.status-v5 {
    background: #91b0dc;
    color: #fff;
    display: block;
    padding: 12px;
    overflow: hidden;
    border-radius: 4px;
}
.vip_content{ 
    padding:10px;
}
.vip_bf{
   width:50%;
   margin-bottom:0.5rem;
}
.vip_bf .icon{
    width: 20px;
}
.vip1_content{ 
}
.vip1_content_wrap{
    display: flex;
    flex-direction: row;
    margin-bottom:1rem;
    flex-wrap: wrap;
    
}
.vip1{
    margin: 0px 0.2rem;
    margin:0.4rem;
}
.minfo{
    position: absolute;
    bottom:0px;
    left:0.7rem;
}
.minfo>p{
    font-size:0.4rem;
    line-height:0.4rem;
}
.vip1_content{
 background: url("../assets/cryimg/v1bg.png");
 padding:0px 0.4rem;
 border-radius: 0px 0px 10px 10px;
 padding:0.4rem;
}
.vip2_content{
 background: url("../assets/cryimg/v2bg.png");
 padding:0px 0.4rem;
 border-radius: 0px 0px 10px 10px;
 padding:0.4rem;
}
.vip3_content{
 background: url("../assets/cryimg/v3bg.png");
 padding:0px 0.4rem;
 border-radius: 0px 0px 10px 10px;
 padding:0.4rem;
}
.vip4_content{
 background: url("../assets/cryimg/v4bg.png");
 padding:0px 0.4rem;
 border-radius: 0px 0px 10px 10px;
 padding:0.4rem;
}
.vip5_content{
 background: url("../assets/cryimg/v5bg.png");
 padding:0px 0.4rem;
 border-radius: 0px 0px 10px 10px;
 padding:0.4rem;
}
 
.level_detail_2{
  
    font-size:12px;
    margin-top:5px;
}
.level_detail_1{
  
    font-weight: bold;
    font-size: 18px;
}
.level_detail>div{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.level_detail{
    display:flex;
    flex-direction: row;
    flex-grow: 1;
}
.item_level_other{
        color:#65814E;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #DFF7CF, #AFE08F);
}
.item_level7 .level_info{
     border-bottom:1px solid #94CE6C;
}
.item_level1{
    color:#825639;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #F6E9D7, #E6C9B2);
   
}
.level_info{
    height:42px;
    flex-grow: 1;
}
.item_level1 .level_info{
  border-bottom:1px solid #DBB59D;
  
}

.item_level2{
    color:#707481;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #EEF3FF, #CAD1D9);
     border-bottom:1px solid #B3C2D3;
}
.item_level2 .level_info{
     border-bottom:1px solid #B3C2D3;
}

.item_level3{
    color:#856445;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #FEFAD8, #F2DFA1);
    
}
.item_level3 .level_info{
      border-bottom:1px solid #E4CC78;
}
.item_level4{
   color:#49737C;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #DEFCFD, #8DDAE2);
    
}
.item_level4 .level_info{
     border-bottom:1px solid #74B8BF;
}
.item_level5{
   color:#455F7E;
    border-radius: 10px;
     background-image: linear-gradient(to right bottom, #D3EAFF, #9EC0F0);
     
}
.item_level5 .level_info{
     border-bottom:1px solid #7FA7E0;
}
.item_level6{
    color:#674E81;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #D6CFF7, #BAAFF4);
    
}
.item_level6 .level_info{
     border-bottom:1px solid #A699E8;
}
.item_level7{
    color:#65814E;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #DFF7CF, #AFE08F);
  
}
.item_level7 .level_info{
      border-bottom:1px solid #94CE6C;
}

.item .level_info{
    height:42px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
   

}
.item .level_info>div{
    flex-grow: 1;
    display: flex;
    align-items: center;
}
.item .level_info>div:nth-child(1){
    padding-left:18px;
    font-size:18px;
}
.item .level_info>div:nth-child(2){
    padding-right:18px;
    font-size:18px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}
.item{
    display:flex;
    flex-direction: column;
}
.levelItems{
    margin:0px 4px;
    padding-bottom:100px;
}
.current_expire_time{
    position:absolute;
    top:54px;
    left:28px; 
    font-size:14px;
    color:#9E9E9E;
}
.current_Task_Num{
    position:absolute;
    bottom:50px;
    right:28px; 
    font-size:16px;
    color:#ffffff;
}
.current_identity_word{
    position:absolute;
    top:24px;
    left:28px;
    color:#ffffff;
    font-size:18px;
    font-weight: 500;
}
.current_Task_level{
     position:absolute;
    bottom:32px;
    right:28px; 
    font-size:16px;
    color:#ffffff;
}
.current_identity{
    margin:4px;
    position: relative;
}
.current_identity img{
    width:100%;
}
  .vip_header .info{
      display:flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      font-size:18px;
      color:#333333;
      font-weight: bold;
      background: #ffffff;
  }
  .vip{ 
      width:100%;
  }
  .item{
  display:flex;
  flex-wrap: wrap;
  margin:10px 14px 20px; 
  
}
.item>div{
  
  height:65px; 
}
</style>
<style></style>
<script>
 import {fetch,post} from '@/util/api'
  import {getBackData,getUserInfo} from '@/util/api'
  import fdialog from './dialog' 
import V1BG from '@/assets/cryimg/VIP1.png';
import V2BG from '@/assets/cryimg/VIP2.png';
import V3BG from '@/assets/cryimg/VIP3.png';
import V4BG from '@/assets/cryimg/VIP4.png';
import V5BG from '@/assets/cryimg/VIP5.png';
import V6BG from '@/assets/cryimg/VIP6.png';
import vl1 from '@/assets/cryimg/icon-vip-label1.png';
import vl2 from '@/assets/cryimg/icon-vip-label2.png';
import vl3 from '@/assets/cryimg/icon-vip-label3.png';
import vl4 from '@/assets/cryimg/icon-vip-label4.png';
import vl5 from '@/assets/cryimg/icon-vip-label5.png';
import vl6 from '@/assets/cryimg/icon-vip-label6.png';
import vl7 from '@/assets/cryimg/icon-vip-label7.png';
import vl8 from '@/assets/cryimg/icon-vip-label8.png';
import vl9 from '@/assets/cryimg/icon-vip-label9.png'
import vl10 from '@/assets/cryimg/icon-vip-label10.png'
export default {
     components:{fDialog:fdialog},
    data(){
        return{
           userGuide:[],
           userInfo:null,
           title:'prompt',
           msg:'',
           code:"",
           showTip:false,
           show:false,
           selectItem:null,
           vips:[],
           vipLogos:{
            1:V1BG,
            2:V2BG,
            3:V3BG,
            4:V4BG,
            5:V5BG,
            6:V6BG
           },
           rightIcons:[vl1,vl2,vl3,vl4,vl5,vl6,vl7,vl8,vl9,vl10],
           rights:{
            1:['Exclusive VIP rates','Exclusive VIP service group'],
            2:['Exclusive VIP rates','Exclusive VIP events','Exclusive VIP service group','Industry reports'],
            3:['Exclusive VIP rates','VIP privilege logo','Exclusive VIP service group','Industry reports','Exclusive holiday privileges and customized accompanying gift privileges','Exclusive VIP events'],
            4:['Exclusive VIP rates','VIP privilege logo','Exclusive VIP service group','Industry reports','Exclusive holiday privileges and customized accompanying gift privileges','Exclusive VIP events','Exclusive 24-hour VIP customer service channel','Exclusive green channel for charging and withdrawing coins'],
            5:['Exclusive VIP rates','VIP privilege logo','Exclusive VIP service group','Industry reports','Exclusive holiday privileges and customized accompanying gift privileges','Exclusive VIP events','Exclusive 24-hour VIP customer service channel','Exclusive green channel for charging and withdrawing coins','Exclusive coin withdrawal limit increase','Priority for new product in-testing']
           },
           preSelected:null
        }
    },
    methods:{
        selectActive:function(item,index){
            console.log("selectActive",item);
            if(this.preSelected!=null){
                this.preSelected.selected=false;
            }
            this.vips[index]=item;
               this.vips[index].selected=true;
               this.preSelected=item;
               this.selectItem=item;
               this.$forceUpdate();
        },
        goRecords:function(){
               this.$router.push({
                    path: "/vrecord" 
                });
        },
        closepop:function(){
             this.show=false;
        },
        purchase:function(item){
             this.show=true; 
             this.code="";
        },
         onClickLeft:function(){
            this.$router.back(-1)
        },
        goBuy:function(){
           console.log("前往购买");
           if(!this.code){
            this.$toast("Please enter key");
            return;
           }
           this.$toast.loading({
            duration:0,
            message: 'buying...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            this.showTip=false;
            post("api/vip/buyvip",{code:this.code,vip_id:this.selectItem.id},this.$i18n).then(res=>{
                if(res.code==1){
                    console.log(res.code_dec);
                    this.$toast(res.code_dec);
                    this.showTip=false;
                    this.show=false;
                    setTimeout(()=>{
                     this.refreshUserInfo();
                    },1000)
                    
                }else{
                    this.$toast(res.code_dec);
                    if(res.code_dec=="Insufficient user balance!"){
                           this.$router.push({
                                path: "/recharge",
                                query:{src:'vip'} 
                            });
                    }
                }
            }).catch(err=>{
                this.$toast("Purchase failed");
            })
            

        },
        cancel:function(){
            console.log("cancel");
           this.showTip=false;

        },
        refreshUserInfo:async function(){
                this.$toast.loading({
                                        duration:0,
                                        message: 'loading...',
                                        forbidClick: true,
                                        loadingType: 'spinner' 
                                        });
                                         this.userInfo=await getUserInfo(1);
                                         this.$toast.clear();
        },
        goLogin:function(){
                 if(this.userInfo==null){
                    this.$router.push({
                                path: "/login" 
                            });
                 }else{
                     return;
                 }
        },
        getVips: async function(){
             var res= await getBackData();  
             var datas=res.datas.vips;
              this.vips=res.datas.vips;
             for(var i in this.vips){
                this.vips[i].selected=false;
             } 
             if(this.vips.length>0){
                this.vips[0].selected=true;;
                this.selectItem= this.vips[0];
                this.preSelected=this.vips[0];
                
             }
               console.log("this.vips",this.vips);
        },
        init: async function (){
            this.$toast.loading({
                        duration:0,
                        message: 'loading...',
                        forbidClick: true,
                        loadingType: 'spinner' 
                        });
             
            console.log("userGuide",this.userGuide);
            this.userInfo=await getUserInfo();
             this.getVips();
             if(this.userInfo==null||!this.userInfo){
                this.$router.push({
                    path: "/login",
                    query:{src:"vip"} 
                });
                this.$toast.clear();
                return;
            }else{
                this.$toast.clear();
            }
            console.log(this.userInfo);
             
        },
        buyVip(item){
            if(item.grade<=this.userInfo.vip_level){
                this.$dialog.alert({
                    title: 'Failure prompt',
                    message: 'You are already '+this.userInfo.useridentity,
                    }).then(() => {
                    // on close
                    });
                    return;
            }
            console.log("buy vip",item);
           this.showTip=true;
           this.msg="Are you sure it will cost "+item.amount+"KSH to renew "+item.name+"?";
           this.selectItem=item;
        }
    },
    created(){
       
    },
        activated(){
        this.init();
        
          if(this.$route.query.index){
             this.$store.dispatch("setMenuActive",parseInt(this.$route.query.index));
           }
            this.$store.dispatch("setShowNav",false);
    },
    mounted(){

    }
}
</script>

