<template>
    <div class="business_hall">
         <div class="business_hall_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">{{$t('title32')}}</div>
                </template>
                
            </van-nav-bar>
        </div>
        <div style="font-size: 15px;
    padding: 15px;line-height:20px;">
                {{$t("context1")}}
                <br>
                {{$t("context2")}}
                <br>
                {{$t("context3")}}
                 <br>
                {{$t("context4")}}
                 <br>
                {{$t("context5")}}
                  <br>
                {{$t("context6")}}
                <br>
                 {{$t("context7")}}
        </div>
        </div>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    methods:{
        onClickLeft:function(){
             this.$router.back(-1)
        }
    },
      activated(){
        this.$store.dispatch("setShowNav",false);
    }
}
</script>