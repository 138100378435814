<template>
    <div class="business_hall">
         <div class="business_hall_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">{{$t("title31")}}</div>
                </template>
                
            </van-nav-bar>
        </div>
        <div class="buywrap">
               <van-cell :title="$t('title33')" value="bound" is-link  />
               <van-cell :title="$t('title34')" is-link  url="#/updatepwd" />
               <van-cell :title="$t('title35')" is-link url="#/updateEmail" /> 

        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    methods:{
        onClickLeft:function(){
              this.$router.back(-1)
        }
    },
    activated(){
        this.$store.dispatch("setShowNav",false);
    }
}
</script>    