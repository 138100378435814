<template>

<div class="login">
      <div class="person_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title"> </div>
                </template>
                
            </van-nav-bar>
        </div>
        <div>
              <div class="login_word">

             {{$t('title86')}}
              </div>
              <div class="login_panel">
                  <div style="margin-bottom:20px;">
                     <div style="font-size:14px;margin-bottom:5px">{{$t('title87')}}</div>
                     <div><input type="text" v-model="user.user_name" class="input-text" :placeholder="$t('title88')"></div>
                  </div>
                   <div style="margin-bottom:20px;">
                     <div style="font-size:14px;margin-bottom:5px">{{$t('title89')}}</div>
                     <div><input type="text" v-model="user.email" class="input-text" :placeholder="$t('title90')"></div>
                    </div>
                    <div style="margin-bottom:20px;">
                     <div style="font-size:14px;margin-bottom:5px">{{$t('title91')}}</div>
                     <div>
                      <select class="input-select" v-model="user.country">
                           <option v-for="(item,index) in countries" :key="index" >{{item.phoneCode}}</option>

                      </select>
                      
                      </div>
                    </div>
                    <div style="margin-bottom:20px;">
                     <div style="font-size:14px;margin-bottom:5px">{{$t('title92')}}</div>
                     <div><input type="text" v-model="user.phone" class="input-text" :placeholder="$t('title93')"></div>
                    </div>
                    <div style="margin-bottom:20px;">
                     <div style="font-size:14px;margin-bottom:5px">{{$t('title79')}}</div>
                     <div><input type="password"   v-model="user.pwd" class="input-text" :placeholder="$t('title72')"></div>
                    </div>
                    <div style="margin-bottom:20px;">
                     <div style="font-size:14px;margin-bottom:5px">{{$t('title80')}}</div>
                     <div><input type="password" v-model="user.pwd2" class="input-text" :placeholder="$t('title83')"></div>
                    </div>
                    <div style="margin-bottom:20px;">
                     <div style="font-size:14px;margin-bottom:5px">KEY</div>
                     <div><input type="password" v-model="user.key" class="input-text" :placeholder="$t('title82')"></div>
                    </div>
                    <div style="margin-bottom:20px;">
                     <div style="font-size:14px;margin-bottom:5px">KEY</div>
                     <div><input type="password" v-model="user.key2" class="input-text" :placeholder="$t('title82')"></div>
                    </div>
                    <div style="margin-bottom:20px;display:flex">
                     <div class=""><input type="text" v-model="user.code" class="input-text" :placeholder="$t('title73')"/></div>
                     <img @click="changeCode" :src="basePath+'/api/user/repcha?code_rand='+user.rkey" style="width:120px;margin-left:10px;"/>
                  </div>
                   <div style="margin-bottom:20px;">
                     <div> <a class="loginbtn   btnactive" @click="confirmRegiste" style=" padding:10px">{{$t('title94')}}</a></div>
                  </div>
                  <div style="padding-bottom:5rem;">
                     <div style="font-size:15px">{{$t('title96')}}?<a class="txtblue" @click="goLogin">{{$t('title95')}}</a></div> 

                  </div>
              </div>

        </div>

</div>
 
</template>
<style>
.txtblue{
  color:#2196f3!important;
}
.btnactive{
      background: #588bf8;
    opacity: 0.97;
    cursor:pointer;
}
::placeholder {
  color: gray;
  opacity: 0.4; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12-18 */
  color: gray;
}
</style>
<style scoped>
.loginbtn{
  border-radius:20px;
  font-size:16px;
  font-weight:bold;
  color:#ffffff;
  display: block;
  text-align:center;
}
.disableBtn{
  background: #999;
}
 .login_panel input {
    resize: none;
    border: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none; 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.input-text, .input-select {
    width: 100%;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 6px;
    color: #0a0a0a;
    background: #f6f6f6;
    font-size: 14px;
    caret-color: #3c6fef;
    outline: none;
    -webkit-transition: all .3s;
    transition: all .3s;
}
.vendor img{
  width:100%;
}
input{
  font-weight: normal;
}
.vendor{
  width: 100px;
  height:28px;
  background: #cccccc;
}
.tip{
  margin-top:10px;
  display: flex;
  flex-direction: row;
  color:#999999;
}
.r{
  color:#333333;
}
.tip>div:nth-child(1){
  margin-right:20px;
}
.tip>a:nth-child(1){
  color:#999999;
}
.tip>a:nth-child(2){
  color:#333333;
}
.loginbtn{
  margin-top:20px;
}
.loginbtn>a{

  height:44px;
  width:152px;
  color:#ffffff;
  background: #F9034F;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:18px;
  font-weight: bold;
}
.rember{
  display:flex;
  flex-direction: row;
  align-items: end; 
  margin-top:-10px;
}
.input_item{
  border-bottom: 1px solid #E5E5E5;
  padding-bottom:10px;
  margin-bottom: 20px;
}
.inputspec{
 margin-left:20px;
}
.input{
  flex-grow: 1;
  border:1px solid transparent; 

}
.input_item>div:nth-child(1){
  font-size:15px;
  font-weight:bold;
}
.input_item>div:nth-child(1){
   margin-top:5px;
   display: flex;
   flex-direction: row;
}
.input_item>div:nth-child(1)>a{
  width:50px;
  background: #ffffff;
  padding:5px;
  display: flex;
  color:#333333;
  border-radius: 2px;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;

}
.input_item>div:nth-child(1)>a::after{
   position: absolute !important;
    top: 50%;
    right: 5px;
    margin-top: -5px;
    border: 3px solid;
    border-color: transparent transparent #333333 #333333;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0.8;
    content: '';
    position: relative;
}
.login_panel{
  margin:24px 34px;
}
.person_header .title{
    font-weight: bold;
    color:#333333;
}
.person_header{
    height:50px;
}
.login_word{
  margin-left:24px;
   margin-top:25px;
   font-size:24px;
   color:#333333;

}
.login_word img{
  width:137px;
}
.login{
  font-size:12px;
}
</style>
<script>
import {fetch} from '@/util/api'
import { getCountries,getCountryCallingCode } from 'libphonenumber-js';
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
export default {
    data(){
        return {
            username: '', 
            password: '',
            re_password:'',
            dest:'86',
            lang:'en',
            recommend:'',
            code_rand:new Date().getTime(),
            basePath:process.env.VUE_APP_BASE_API,
            code:'',
            src:'',
            countries: [],
            canedit:false,
            user:{
              user_name:"",
              pwd:"",
              pwd2:"",
              key:"",
              email:"",
              country:"",
              phone:"",
              rkey:generateUUID(),
              code:""
            }
        }
    },
    methods:{ 
        getCountriesWithPhoneCodes() {
      // 获取所有国家及其电话区号
      const countries = getCountries();
      console.log(countries);
      this.countries = countries.map(country => {
        return {
          country,
          phoneCode: "+"+getCountryCallingCode(country)
        };
      });
      const uniqueCountries = Array.from(
        new Map(this.countries.map(item => [item.phoneCode, item])).values()
      );

      const sortedCountries = uniqueCountries.sort((a, b) => {
        return a.phoneCode.localeCompare(b.phoneCode);
      });
      this.countries=sortedCountries;
      this.user.country=this.countries[0].phoneCode;
    },
      goLogin:function(){
 this.$router.push({
                    path: "/login",
                });
      },
      onClickLeft:function(){
              this.$router.back(-1) 
      },
      changeCode:function(){

         this.user.rkey=generateUUID();
      },
      confirmRegiste:function(){
         if(!this.user.user_name){
          this.$toast(this.$t("title128"));
          return;
        } 
        this.onSubmit();
      },
      autoLogin:function(){
        //注册成功后自动登录
           this.$toast.loading({
            duration:0,
            message: this.$t('title127')+'...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
             fetch("api/user/login",{user_name:this.user.user_name,pwd:this.user.pwd,lang:'en'}).then(res=>{
            console.log("res",res);
            this.$store.dispatch('setToken',res.info);
            console.log("getter",this.$store.getters.userInfo);
            console.log("登录成功，回跳地址:"+this.src);
            this.$toast.clear();
            if(!this.src){
             this.$router.push({
                    path: "/",
                    query:{
                      index:0
                    }
                });
            }else{
             this.$router.push({
                    path: "/"+this.src,
                });
            }

        }).catch(err=>{
           this.$toast.clear();
        })
      },
      onSubmit(values) {

        if(this.user.user_name){
          this.user.user_name=this.user.user_name.trim();
        }
        if(!this.user.phone){
          this.$toast(this.$t('title128'));
          return;
        }  
        if(!this.user.email){
          this.$toast(this.$t('title129'));
          return;
        }  
        if(!this.user.pwd){
          this.$toast(this.$t('title130'));
          return;
        }
        if(!this.user.pwd2){
          this.$toast(this.$t('title131'));
          return;
        }

        if(this.user.pwd!=this.user.pwd2){
           this.$toast(this.$t('title132'));
          return;
        }
        if(!this.user.key){
          this.$toast(this.$t('title133'));
          return;
        }
        if(!this.user.key2){
          this.$toast(this.$t('title134'));
          return;
        }
        if(this.user.key!=this.user.key2){
            this.$toast(this.$t("title135"));
          return;
        }
        if(!this.user.code){
          this.$toast(this.$t("title136"));
          return;
        }
          this.$toast.loading({
            duration:0,
            message: this.$t('title127')+'...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
      console.log('submit', values);
        fetch("api/user/register",this.user).then(res=>{
            console.log("res",res);
             this.$toast.clear();
            if(res.code==1){
              this.$toast(res.code_dec);
               setTimeout(() => {
                 this.autoLogin();
               }, 1000);
            }else{
              this.user.rkey=generateUUID();
              this.$toast(res.code_dec);
            }
            
           
        }).catch(err=>{
          
          this.$toast.clear();
          this.$toast(this.$t("title137"));
        })
      },
       
    },
      activated(){
        this.$store.dispatch("setShowNav",false);

        for(var i in this.user){
          this.user[i]="";
        }
        this.user["rkey"]=generateUUID(); 
        this.user.country=this.countries[0].phoneCode;

  },created() {
    this.getCountriesWithPhoneCodes();
  },
    
    mounted(){
        console.log("params",this.$route.query);
        this.recommend=this.$route.query.id_code;
        if(this.recommend){
           this.canedit=true;
        }else{
           this.canedit=false;
        }
      
      console.log("getter",this.$store.getters.userInfo);
    }
}
</script>

